import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import VolumeSpray25Landing from 'components/VolumeSpray25Landing';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function VS25LTELandingPage(props) {
    let {
        data: { contentfulVs25LteLanding },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulVs25LteLanding,
                    'seoListing.description.description'
                )}
                title={get(contentfulVs25LteLanding, 'seoListing.seoTitle')}
                slug={'volume-spray-25-throwback'}
            />
            <VolumeSpray25Landing page={contentfulVs25LteLanding} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulVs25LteLanding(node_locale: { eq: $locale }) {
            id
            videoBannerPreview {
                localFile {
                    publicURL
                }
            }
            videoBanner {
                localFile {
                    publicURL
                }
            }
            banner {
                ...SlideFields
            }
            section1title
            section1Subtitle
            section1Text {
                raw
            }
            section2BackgroundGradient
            section2Title
            section2TitleImage {
                localFile {
                    publicURL
                }
            }
            section2Image {
                localFile {
                    publicURL
                }
            }
            section2Image1 {
                localFile {
                    publicURL
                }
            }
            section2Text1
            section2Image2 {
                localFile {
                    publicURL
                }
            }
            section2Text2
            section2Image3 {
                localFile {
                    publicURL
                }
            }
            section2Text3
            section2Image4 {
                localFile {
                    publicURL
                }
            }
            section2Text4
            section2Logo {
                localFile {
                    publicURL
                }
            }
            section2ButtonUrl
            section2ButtonImage {
                localFile {
                    publicURL
                }
            }
            mostWantedTitle
            tutorialVideos {
                ...ContentfulVideoFields
            }
            spotifyBackgroundColor
            spotifyTitle
            spotifyUrl
            spotifyImage {
                localFile {
                    publicURL
                }
            }
            spotifyButtonImage {
                localFile {
                    publicURL
                }
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
            newMostWanted {
                fragments {
                    key
                    value
                    type
                }
            }
        }
    }
`;

/*
            tutorialVideos {
                localFile {
                    publicURL
                }
            }

*/
