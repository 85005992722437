import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import HeroSlider from 'components/HeroSlider';

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';
import { formatHTML } from 'helpers/text';
import parse from 'html-react-parser';

import {
    Spacing,
    SharedStyles,
    Container,
    ProductStyle,
    ImageTextInfo,
    VideoBlock,
    StyleBlock,
    ImageTextGrid,
    ImageTextTile,
    ImageTextGridImage,
    ThrowbackPanel,
    SpotifyPanel,
    VideoRow,
} from '../kenra-storybook/index';

const {
    StSectionInfo,
    StSectionMobileViewMore,
    StSectionTitle,
    StLinkMore,
    StTitle,
    StPageImageTitleBar,
    StBigTitle,
    StMediumTitle,
    StBigTitleGradient,
    StMediumTitleGradient,
} = SharedStyles;

const VS25Landing = ({ intl, page }) => {
    //    let video1id = getYouTubeId(page.video1Url);
    //    let video2id = getYouTubeId(page.video2Url);

    let items = [];
    if (page.section2Text1) {
        items.push({
            text: page.section2Text1,
            image: get(page, 'section2Image1.localFile.publicURL')
                ? get(page, 'section2Image1.localFile.publicURL')
                : '/images/back-arrow.png',
        });
    }
    if (page.section2Text2) {
        items.push({
            text: page.section2Text2,
            image: get(page, 'section2Image2.localFile.publicURL')
                ? get(page, 'section2Image2.localFile.publicURL')
                : '/images/back-arrow.png',
        });
    }
    if (page.section2Text3) {
        items.push({
            text: page.section2Text3,
            image: get(page, 'section2Image3.localFile.publicURL')
                ? get(page, 'section2Image3.localFile.publicURL')
                : '/images/back-arrow.png',
        });
    }
    if (page.section2Text4) {
        items.push({
            text: page.section2Text4,
            image: get(page, 'section2Image4.localFile.publicURL')
                ? get(page, 'section2Image4.localFile.publicURL')
                : '/images/back-arrow.png',
        });
    }

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <VideoBlock
                    renderVideo
                    imgUrl={get(page, 'videoBannerPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'videoBanner.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
            </Spacing>
            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <StSectionInfo useMobileBg>
                    <Container>
                        <StBigTitleGradient
                            gradient={page.section2BackgroundGradient}
                            style={{
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            {page.section1title}
                        </StBigTitleGradient>
                        <StMediumTitle>{page.section1Subtitle}</StMediumTitle>

                        {documentToReactComponents(
                            JSON.parse(get(page, 'section1Text.raw') || '{}')
                        )}
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing
                removeSpaceTop
                removeSpaceTopMobile
                removeSpaceBottomMobile
            >
                <Container>
                    <HeroSlider slides={page.banner} />
                </Container>
            </Spacing>

            <Spacing removeSpaceTop>
                <StSectionInfo
                    style={{
                        backgroundImage: `linear-gradient(${page.section2BackgroundGradient})`,
                    }}
                >
                    <Container>
                        <ThrowbackPanel
                            titleImage={get(
                                page,
                                'section2TitleImage.localFile.publicURL'
                            )}
                            image={get(
                                page,
                                'section2Image.localFile.publicURL'
                            )}
                            items={items}
                            petaImage={get(
                                page,
                                'section2Logo.localFile.publicURL'
                            )}
                            buttonUrl={page.section2ButtonUrl}
                            gradient={page.section2BackgroundGradient}
                        />
                    </Container>
                </StSectionInfo>
            </Spacing>

            {page.newMostWanted && (
                <Spacing removeSpaceTop>
                    <StSectionInfo useMobileBg>
                        <Container>
                            <StMediumTitleGradient
                                gradient={page.section2BackgroundGradient}
                                style={{
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {page.mostWantedTitle}
                            </StMediumTitleGradient>
                            <ImageTextGridImage
                                rowRevers
                                items={page.newMostWanted.fragments.map(
                                    tile => {
                                        let returnData = {};
                                        tile.forEach(fragmentData => {
                                            switch (fragmentData.key) {
                                                case 'Image':
                                                    returnData['img'] =
                                                        fragmentData.value;
                                                    break;
                                                case 'Text':
                                                    returnData['text'] =
                                                        fragmentData.value;
                                                    break;
                                                case 'ReviewImage':
                                                    returnData['reviewImg'] =
                                                        fragmentData.value;
                                                    break;
                                                default:
                                                    break;
                                            }
                                        });
                                        return {
                                            image: returnData.img,
                                            text: parse(returnData.text),
                                            textImage: returnData.reviewImg,
                                        };
                                    }
                                )}
                            />
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}

            {page.tutorialVideos && (
                <Spacing>
                    <StSectionMobileViewMore>
                        <StSectionTitle>
                            <Container>
                                <h2>
                                    {intl.formatMessage({
                                        id: 'kenra-latest-tutorials',
                                    })}
                                </h2>
                            </Container>
                        </StSectionTitle>
                        <Container>
                            <VideoRow
                                items={page.tutorialVideos.map(video => {
                                    let youTubeId = getYouTubeId(
                                        video.youtubeLink
                                    );
                                    return {
                                        btnSizeMedium: true,
                                        bgImg: getPreviewUrl(youTubeId),
                                        videoType: 'youtube',
                                        autoPlay: true,
                                        videoOptions: [
                                            { src: getEmbedUrl(youTubeId) },
                                        ],
                                    };
                                })}
                            />
                        </Container>
                    </StSectionMobileViewMore>
                </Spacing>
            )}
            <Spacing removeSpaceTop removeSpaceBottom>
                <StSectionInfo
                    style={{
                        backgroundColor: page.spotifyBackgroundColor,
                    }}
                >
                    <Container>
                        <SpotifyPanel
                            title={page.spotifyTitle}
                            image={get(
                                page,
                                'spotifyImage.localFile.publicURL'
                            )}
                            buttonUrl={page.spotifyUrl}
                            buttonImage={get(
                                page,
                                'spotifyButtonImage.localFile.publicURL'
                            )}
                        />
                    </Container>
                </StSectionInfo>
            </Spacing>
        </>
    );
};

export default injectIntl(VS25Landing);
